import React, { useState, useEffect } from 'react';
import FormButtonRow from '../objects/FormButtonRow';
import InputMask from 'react-input-mask';
import { FaSearch } from 'react-icons/fa';
import './EnderecoForm.css';
import { useModal } from '../objects/ModalProvider';
import { useAuth } from '../auth/AuthContext';

const EnderecoForm = ({ endereco: initialEndereco, onSave, onCancel, submitLabel }) => {
    const [ufs, setUfs] = useState([]);
    const [endereco, setEndereco] = useState(initialEndereco || {
        idClienteExterno: initialEndereco?.idClienteExterno || '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        idUf: '',
        cep: '',
        tipoEndereco: '',
    });
    const [loading, setLoading] = useState(false);
    const [cepError, setCepError] = useState('');
    const { showModal } = useModal();
    const { token } = useAuth();

    useEffect(() => {
        const fetchEndereco = async () => {
            if (initialEndereco?.id) {
                try {
                    const response = await fetch(`./endereco?idEndereco=${initialEndereco.id}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                    const text = await response.text();
                    try {
                        const data = JSON.parse(text);
                        setEndereco({
                            ...data,
                            idUf: data.idUF || ''
                        });
                    } catch (error) {
                        console.error('Failed to parse JSON:', text);
                        throw error;
                    }
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            }
        };

        const fetchUfs = async () => {
            try {
                const response = await fetch('./endereco/ufs', { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const text = await response.text();
                try {
                    const data = JSON.parse(text);
                    setUfs(data);
                } catch (error) {
                    console.error('Failed to parse JSON:', text);
                    throw error;
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchEndereco();
        fetchUfs();
    }, [initialEndereco, token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const method = endereco.id ? 'PUT' : 'POST';
        const url = endereco.id ? `./endereco?idEndereco=${endereco.id}` : './endereco';

        try {
            debugger;

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(endereco)
            });
            if (response.ok) {
                onSave("Endereço salvo com sucesso!");
            } else {
                const text = await response.text();
                try {
                    const data = JSON.parse(text);
                    const errorMessages = Object.entries(data.errors || {})
                        .map(([key, value]) => `${key}: ${value.join(', ')}`)
                        .join('\n');
                    showModal('Erro ao salvar usuário:', errorMessages, 'error');
                } catch (error) {
                    throw error;
                }
            }
        } catch (error) {
            showModal('Erro na requisição:', 'Erro técnico ao salvar. Tente novamente.', 'error');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEndereco(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCepSearch = async () => {
        if (endereco.cep) {
            try {
                setLoading(true);
                setCepError('');
                const response = await fetch(`./endereco/consultarCep?cep=${endereco.cep}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const text = await response.text();
                try {
                    if (response.ok) {
                        const addressData = JSON.parse(text);
                        setEndereco(prevState => ({
                            ...prevState,
                            rua: addressData.logradouro,
                            complemento: addressData.complemento,
                            bairro: addressData.bairro,
                            cidade: addressData.localidade,
                            idUf: ufs.find(uf => uf.sigla === addressData.uf)?.id || prevState.idUf,
                        }));
                    } else {
                        const data = JSON.parse(text);
                        setCepError(data.message);
                    }
                } catch (error) {
                    console.error('Failed to parse JSON:', text);
                    throw error;
                }
            } catch (error) {
                setCepError(error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCepKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleCepSearch();
        }
    };

    return (
        <form onSubmit={handleSubmit} className="endereco-form">
            <div className="form-row">
                <div className="form-group">
                    <label>CEP:</label>
                    <div className="form-group-inline">
                        <InputMask
                            mask="99.999-999"
                            maskChar={null}
                            name="cep"
                            value={endereco.cep || ''}
                            onChange={handleChange}
                            onKeyDown={handleCepKeyDown}
                            className="form-control"
                            type="cep"
                            required
                        />
                        <button type="button" className="cep-search-btn" onClick={handleCepSearch} disabled={loading}>
                            <FaSearch className="fa-search" />
                        </button>
                    </div>
                    {cepError && <small className="form-text text-danger">{cepError}</small>}
                </div>
                <div className="form-group">
                    <label>Tipo de Endereço:</label>
                    <select
                        name="tipoEndereco"
                        value={endereco.tipoEndereco}
                        onChange={handleChange}
                        className="form-control"
                        type="listbox"
                        required
                    >
                        <option value="">Selecione um tipo</option>
                        <option value="Residencial">Residencial</option>
                        <option value="Comercial">Comercial</option>
                        <option value="Outro">Outro</option>
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>Logradouro:</label>
                    <input
                        type="text"
                        name="rua"
                        value={endereco.rua || ''}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <label>Número:</label>
                    <input
                        type="text"
                        name="numero"
                        value={endereco.numero || ''}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>Complemento:</label>
                    <input
                        type="text"
                        name="complemento"
                        value={endereco.complemento || ''}
                        onChange={handleChange}
                        className="form-control"
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <label>Bairro:</label>
                    <input
                        type="text"
                        name="bairro"
                        value={endereco.bairro || ''}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>Cidade:</label>
                    <input
                        type="text"
                        name="cidade"
                        value={endereco.cidade || ''}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <label>Estado:</label>
                    <select
                        name="idUf"
                        value={endereco.idUf}
                        onChange={handleChange}
                        className="form-control"
                        type="listbox"
                        required
                        disabled={loading}
                    >
                        <option value="">Selecione um estado</option>
                        {ufs.map(uf => (
                            <option key={uf.id} value={uf.id}>
                                {uf.nome}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <FormButtonRow onSubmit={handleSubmit} onCancel={() => onCancel()} submitLabel={submitLabel} />
        </form>
    );
};

export default EnderecoForm;
