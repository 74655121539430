import React, { useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useAuth } from '../auth/AuthContext';
import ProtectedLink from '../auth/ProtectedLink';

const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const { username, logout, userId } = useAuth();
    const [userSections, setUserSections] = useState([]);
    const { token } = useAuth();

    const toggleNavbar = () => setCollapsed(!collapsed);

    const closeNavbar = () => {
        if (!collapsed) {
            setCollapsed(true);
        }
    };

    useEffect(() => {
        const fetchUserMenus = async () => {
            try {
                const response = await fetch(`./home/menuusuario?idUsuario=${userId}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const data = await response.json();
                const formattedUserSections = data.map(menu => ({
                    id: menu.id,
                    nome: menu.nome,
                    urlMenu: menu.urlMenu,
                }));
                setUserSections(formattedUserSections);
            } catch (error) {
                console.error('Error fetching user menus:', error);
            }
        };

        fetchUserMenus();
    }, [userId, token]);

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                <NavbarBrand tag={Link} to="/" onClick={closeNavbar} id="navbar-logo">
                    <img src="https://topgpt.azureedge.net/site/logo_menu_linha.png" alt="Logo topGPT" style={{ height: '40px' }} />
                </NavbarBrand>
                <UncontrolledTooltip placement="bottom" target="navbar-logo" className="custom-tooltip">
                    {userId ? `Bem-vindo, ${username}` : "I.A. para todos!"}
                </UncontrolledTooltip>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        {userSections.map((section, index) => (
                            <ProtectedLink key={index} onClick={closeNavbar}>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to={section.urlMenu}>{section.nome}</NavLink>
                                </NavItem>
                            </ProtectedLink>
                        ))}
                        {!userId && (
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/login" onClick={closeNavbar}>Login</NavLink>
                            </NavItem>
                        )}
                        {userId && (
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" onClick={() => { logout(); closeNavbar(); }} to="/">
                                    Logout
                                </NavLink>
                            </NavItem>
                        )}
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
};

export default NavMenu;
