import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import HomeUser from './HomeUser';
import HomeGeral from './HomeGeral';

const Home = () => {
    const { userId, anonToken, setAnonToken, browserInfo, ipInfo } = useAuth();
    const [loading, setLoading] = useState(true);
    const [isFetchingAnonToken, setIsFetchingAnonToken] = useState(false);

    useEffect(() => {
        if (
            !anonToken &&
            browserInfo &&
            Object.keys(browserInfo).length > 0 &&
            ipInfo &&
            Object.keys(ipInfo).length > 0 &&
            !isFetchingAnonToken
        ) {
            setIsFetchingAnonToken(true);

            const userLogin = {
                username: 'jota_anonimo',
                password: 'jota_test3',
                browserInfo: browserInfo,
                ipInfo: ipInfo
            };

            fetch('login/auth-anom', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userLogin)
            })
                .then(response => response.json())
                .then(data => {
                    setAnonToken(data.token);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Erro ao buscar token an�nimo:', error);
                    setLoading(false);
                });
        } else if (anonToken) {
            setLoading(false);
        }
    }, [anonToken, browserInfo, ipInfo, setAnonToken, isFetchingAnonToken]);


    if (loading) {
        return <div>Carregando...</div>;
    }

    return (
        <div className="page-container">
            {userId ? <HomeUser /> : <HomeGeral />}
        </div>
    );
};

export default Home;
