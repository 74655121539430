import NewUser from "./components/NewUser";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import NewUserForm from "./components/NewUserForm";
import Endereco from "./protected/EnderecoList";
import EnderecoForm from "./components/EnderecoForm";
import PagamentoForm from "./components/PagamentoForm";
import Login from "./components/Login";
import RecuperaSenha from "./components/RecuperaSenha";
import ProtectedRoute from './auth/ProtectedRoute'; 

import AgendaForm from "./protected/AgendaForm";
import ControleAgenda from "./protected/ControleAgenda";
import Agenda from "./protected/Agenda";

import ControleCliente from './protected/ControleCliente';
import Cliente from './protected/Cliente';
import ClienteForm from "./protected/ClienteForm";

import { ControleAssistente } from "./protected/ControleAssistente";
import Cadastro from './protected/cadastro/Cadastro';

import ControleUsuario from './protected/ControleUsuario';
import Usuario from './protected/Usuario';
import UsuarioForm from "./protected/UsuarioForm";

import TopChef from "./components/TopChef";
import TopSecretario from "./components/TopSecretario";


const AppRoutes = [
    {
    index: true,
    element: <Home />
    },
    {
        path: '/NewUser/:assist',
        element: <NewUser />
    },
    {
        path: '/ControleAgenda',
        element: <ProtectedRoute><ControleAgenda /></ProtectedRoute>
    },
    {
        path: '/Agenda',
        element: <ProtectedRoute><Agenda /></ProtectedRoute>
    },
    {
        path: '/Agenda/:agendaId',
        element: <ProtectedRoute><Agenda /></ProtectedRoute>
    },
    {
        path: '/Cadastro',
        element: <ProtectedRoute><Cadastro /></ProtectedRoute>
    },
    {
        path: '/ControleAssistente',
        element: <ProtectedRoute><ControleAssistente /></ProtectedRoute>
    },
    {
        path: '/ControleCliente',
        element: <ProtectedRoute><ControleCliente /></ProtectedRoute>
    },
    {
        path: '/ControleUsuario',
        element: <ProtectedRoute><ControleUsuario /></ProtectedRoute>
    },
    {
        path: '/Cliente/new',
        element: <ProtectedRoute><Cliente /></ProtectedRoute>
    },
    {
        path: '/Cliente/edit/:id',
        element: <ProtectedRoute><Cliente /></ProtectedRoute>
    },
    {
        path: '/ClienteForm/edit/:id',
        element: <ProtectedRoute><ClienteForm /></ProtectedRoute>
    },
    {
        path: '/NewUserForm',
        element: <NewUserForm />
    },
    {
        path: '/PrivacyPolicy',
        element: <PrivacyPolicy />
    },
    {
        path: '/Usuario/new',
        element: <ProtectedRoute><Usuario /></ProtectedRoute>
    },
    {
        path: '/Usuario/edit/:id',
        element: <ProtectedRoute><Usuario /></ProtectedRoute>
    },
    {
        path: '/UsuarioForm/edit/:id',
        element: <ProtectedRoute><UsuarioForm /></ProtectedRoute>
    },

    {
        path: '/Endereco',
        element: <Endereco />
    },
    {
        path: '/Endereco/new',
        element: <EnderecoForm />
    },
    {
        path: '/PagamentoForm',
        element: <PagamentoForm />
    },
    {
        path: '/AgendaForm/:userId',
        element: <AgendaForm />
    },
    {
        path: '/Login',
        element: <Login />
    },
    {
        path: '/RecuperaSenha',
        element: <RecuperaSenha />
    },
    {
        path: '/TopChef',
        element: <TopChef />
    },
    {
        path: '/TopSecretario',
        element: <TopSecretario />
    }
];

export default AppRoutes;
