import React, { useState, useEffect, useCallback, useRef } from 'react';
import './ControleUsuario.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { useModal } from '../objects/ModalProvider';
import { useAuth } from '../auth/AuthContext';

const ControleUsuario = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [usuarios, setUsuarios] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage] = useState(8);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState(location.state?.searchQuery || '');
    const observer = useRef();
    const { showModal } = useModal();
    const { token } = useAuth();

    const fetchUsuarios = useCallback(async (page, search) => {
        if (page > totalPages) return;
        setLoading(true);
        try {
            const response = await fetch(`./usuario?page=${page}&limit=${clientsPerPage}&search=${search}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erro na requisição');
            }
            const data = await response.json();
            setUsuarios(prevUsuarios => (page === 1 ? data.usuarios : [...prevUsuarios, ...data.usuarios]));
            setTotalPages(data.totalPages);
        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(null), 3000);
        } finally {
            setLoading(false);
        }
    }, [clientsPerPage, totalPages, token]);

    useEffect(() => {
        if (searchQuery === "") {
            fetchUsuarios(currentPage, searchQuery);
        }
        if (location.state?.message) {
            location.state.message = "";
            fetchUsuarios(currentPage, searchQuery);
        }
    }, [currentPage, fetchUsuarios, searchQuery, location.state]);

    const confirmaDelete = (id) => {
        showModal('Confirmar exclusão', 'Você tem certeza que deseja excluir este usuário?', 'question', null, null, () => handleDelete(id));
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`./usuario?id=${id}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}`, } 
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erro ao deletar usuário');
            }
            setUsuarios(usuarios.filter(usuario => usuario.id !== id));
        } catch (err) {
            setError(err.message);
            setTimeout(() => setError(null), 3000);
        }
    };

    const handleEdit = (id) => {
        navigate(`/usuario/edit/${id}`, { state: { searchQuery } });
    };

    const getDisplayName = (fullName) => {
        const nameParts = fullName.split(' ');
        if (nameParts.length > 2) {
            return `${nameParts[0]} ${nameParts[nameParts.length - 1]}`;
        }
        return fullName;
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setUsuarios([]);
        setCurrentPage(1);
        fetchUsuarios(1, searchQuery);
    };

    const lastCardElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && currentPage < totalPages) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, currentPage, totalPages]);

    function formatPhoneNumber(phoneNumber) {
        let digits = phoneNumber.replace(/\D/g, '');
        if (digits.startsWith('55') && digits.length >= 12) {
            digits = digits.substring(2);
        }
        if (parseInt(digits[2]) >= 6) {
            return digits.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else {
            return digits.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        }
    }

    return (
        <div>
            <div className="header">
                <h2>Usuários</h2>
            </div>
            <div className="controls">
                <form className="search-form" onSubmit={handleSearchSubmit}>
                    <div className="search-input-container">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="search-input"
                        />
                        <button type="submit" className="search-btn">
                            <FaSearch />
                        </button>
                    </div>
                    <Link to="/usuario/new" className="btn btn-primary" state={{ searchQuery }}>Novo</Link>
                </form>
                {error && <p className="error">{error}</p>}
            </div>
            <div className="card-usuario-container">
                {usuarios.map((usuario, index) => (
                    <div
                        className="card-usuario"
                        key={usuario.id}
                        onClick={() => handleEdit(usuario.idExterno)}
                        ref={index === usuarios.length - 1 ? lastCardElementRef : null}
                    >
                        <div className="card-usuario-header">
                            <div className="title">{getDisplayName(usuario.nome)}</div>
                            <div className="card-usuario-actions" onClick={(e) => e.stopPropagation()}>
                                <button className="btn-delete" onClick={() => confirmaDelete(usuario.id)}>
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-usuario-content">
                            <p>Telefone: {formatPhoneNumber(usuario.telefone)}</p>
                            <p>Email: {usuario.email}</p>
                        </div>
                    </div>
                ))}
            </div>
            {loading && <div className="loading-message">Carregando...</div>}
            {currentPage >= totalPages && !loading && <div className="end-message">Não existem mais registros</div>}
        </div>
    );
};

export default ControleUsuario;
