import React, { useEffect, useState } from 'react';
import './ContextoForm.css';
import { useAuth } from '../../auth/AuthContext';
import { FaTrash, FaSave, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useModal } from '../../objects/ModalProvider';

const ContextoForm = () => {
    const [contextos, setContextos] = useState([]);
    const { userId, token } = useAuth();
    const { showModal } = useModal();

    useEffect(() => {
        const fetchContextos = async () => {
            try {
                const response = await fetch(`./usuario/contextoassistente?idusuario=${userId}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const text = await response.text();

                try {
                    const data = JSON.parse(text);
                    const contextos = data || [];
                    setContextos(contextos);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    console.error('Response text:', text);
                }
            } catch (error) {
                console.error('Error fetching contextos:', error);
            }
        };

        fetchContextos();
    }, [userId, token]);

    const groupedContextos = contextos.reduce((acc, contexto) => {
        if (!acc[contexto.nomeAssistente]) {
            acc[contexto.nomeAssistente] = {
                idUsuarioAssistente: contexto.idUsuarioAssistente,
                idUsuAssist: contexto.idUsuAssist,
                contextos: []
            };
        }
        if (contexto.id !== null) {
            acc[contexto.nomeAssistente].contextos.push(contexto);
        }
        return acc;
    }, {});

    const [expandedGroups, setExpandedGroups] = useState({});

    const handleGroupToggle = (groupName) => {
        setExpandedGroups(prevState => ({
            ...prevState,
            [groupName]: !prevState[groupName],
        }));
    };

    const handleAddContexto = async (nomeAssistente) => {
        const idUsuarioAssistente = groupedContextos[nomeAssistente].idUsuarioAssistente;
        const idUsuAssist = groupedContextos[nomeAssistente].idUsuAssist;
        const newContexto = {
            id: null,
            idUsuarioAssistente: idUsuarioAssistente,
            idUsuAssist: idUsuAssist,
            chave: '',
            valor: '',
            assistenteFilho: false,
            nomeAssistente: nomeAssistente,
        };

        try {
            const response = await fetch(`./usuario/contextoassistente`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(newContexto),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const createdContexto = await response.json();
            setContextos(prevState => [...prevState, createdContexto]);
            setExpandedGroups(prevState => ({ ...prevState, [nomeAssistente]: true }));
        } catch (error) {
            console.error('Error adding contexto:', error);
        }
    };

    const handleDeleteContexto = async (id) => {
        try {
            const response = await fetch(`./usuario/contextoassistente?id=${id}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}`, } 
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setContextos(prevState => prevState.filter(contexto => contexto.id !== id));
        } catch (error) {
            console.error('Error deleting contexto:', error);
        }
    };

    const confirmDeleteContexto = (id) => {
        showModal('Confirmar exclusão', 'Você tem certeza que deseja excluir este contexto?', 'question', null, null, () => handleDeleteContexto(id));
    };

    const handleUpdateContexto = async (updatedContexto) => {
        try {
            const response = await fetch(`./usuario/contextoassistente`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, 
                },
                body: JSON.stringify(updatedContexto),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedResponse = await response.json();
            setContextos(prevState => prevState.map(contexto =>
                contexto.id === updatedResponse.id ? updatedResponse : contexto
            ));
        } catch (error) {
            console.error('Error updating contexto:', error);
        }
    };

    const handleChange = (id, key, value) => {
        setContextos(prevState =>
            prevState.map(contexto =>
                contexto.id === id ? { ...contexto, [key]: value } : contexto
            )
        );
    };

    return (
        <div className="contexto-page-container">
            <h4>Contextos</h4>
            <div className="contexto-sections-container">
                {Object.keys(groupedContextos).map((nomeAssistente, index) => (
                    <div className="contexto-group" key={index}>
                        <div className="contexto-group-header" onClick={() => handleGroupToggle(nomeAssistente)}>
                            <h5>
                                {expandedGroups[nomeAssistente] ? <FaChevronUp /> : <FaChevronDown />}
                                <span className="contexto-group-title">{nomeAssistente}</span>
                            </h5>
                            {expandedGroups[nomeAssistente] && (
                                <button
                                    className="contexto-btn contexto-novo-contexto-btn"
                                    onClick={() => handleAddContexto(nomeAssistente)}
                                >
                                    Novo
                                </button>
                            )}
                        </div>
                        <div className={`contexto-items ${expandedGroups[nomeAssistente] ? 'expanded' : 'collapsed'}`}>
                            {expandedGroups[nomeAssistente] && (
                                <>
                                    {groupedContextos[nomeAssistente].contextos.map((contexto) => (
                                        <div className="contexto-item" key={contexto.id}>
                                            <label className="contexto-item-label">Chave</label>
                                            <input
                                                type="text"
                                                value={contexto.chave || ''}
                                                placeholder="Chave"
                                                onChange={(e) => handleChange(contexto.id, 'chave', e.target.value)}
                                            />
                                            <label className="contexto-item-label">Valor</label>
                                            <input
                                                type="text"
                                                value={contexto.valor || ''}
                                                placeholder="Valor"
                                                onChange={(e) => handleChange(contexto.id, 'valor', e.target.value)}
                                            />
                                            <label className="contexto-item-checkbox">
                                                Assistente Filho
                                                <input
                                                    type="checkbox"
                                                    checked={contexto.assistenteFilho || false}
                                                    onChange={(e) => handleChange(contexto.id, 'assistenteFilho', e.target.checked)}
                                                />
                                            </label>
                                            <div className="contexto-btn-group">
                                                <button
                                                    className="contexto-btn contexto-btn-danger"
                                                    onClick={() => confirmDeleteContexto(contexto.id)}
                                                >
                                                    <FaTrash />
                                                </button>
                                                <button
                                                    className="contexto-btn contexto-btn-success"
                                                    onClick={() => handleUpdateContexto(contexto)}
                                                >
                                                    <FaSave />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContextoForm;
