import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SliderTG from '../objects/SliderTG';
import './HomeGeral.css';
import { useAuth } from '../auth/AuthContext';

const HomeGeral = () => {
    const navigate = useNavigate();
    const [sections, setSections] = useState([]);
    const { anonToken } = useAuth();

    useEffect(() => {
        // Adicionar um pequeno atraso para garantir que o scroll aconteça após o carregamento do layout
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100); // 100ms de atraso

        const fetchAssistentes = async () => {
            try {
                const response = await fetch('./home/buscaassistentes', { method: 'GET', headers: { 'Authorization': `Bearer ${anonToken}`, } });
                const data = await response.json();
                const formattedSections = data.map(assistente => ({
                    title: assistente.nome,
                    description: assistente.descricao,
                    imageUrl: assistente.urlImagem,
                    textBtn: assistente.valor > 0 ? 'Comece já' : 'Teste grátis',
                    assist: assistente.id,
                }));
                setSections(formattedSections);
            } catch (error) {
                console.error('Error fetching assistentes:', error);
            }
        };

        fetchAssistentes();

        return () => clearTimeout(timer); 
    }, [anonToken]);

    return (
        <div className="page-container">
            <SliderTG />

            <div className="intelligence-section">
                <div className="col">
                    <h1>Inteligência Artificial</h1>
                    <p>
                        A IA está impulsionando mudanças em todos os setores. Desde IA generativa e reconhecimento de fala até imagens médicas e gerenciamento aprimorado da cadeia de suprimentos, a IA está fornecendo às empresas o poder computacional, as ferramentas e os algoritmos que suas equipes precisam para realizar o trabalho de suas vidas.
                    </p>
                </div>
                <div className="col">
                    <img src="https://topgpt.azureedge.net/site/IA_02.jpg" alt="Todos Juntos" className="lateral-image" />
                </div>
            </div>
            <h1>Nossos produtos</h1>
            <div className="sections-container">
                {sections.map((section, index) => (
                    <div className="card" key={index}>
                        <img src={section.imageUrl} alt={section.title} className="card-image" />
                        <div className="card-content">
                            <button className="btn btn-primary" onClick={() => navigate(`./newuser/${section.assist}`)}>{section.textBtn}</button>
                            <div>
                                <h2>{section.title}</h2>
                                <p>{section.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <footer className="footer">
                <div className="footer-column">
                    <h3>TOPGPT Tecnologia LTDA</h3>
                    <span>CNPJ: 57.006.993/0001-26</span>
                    <span>RUA JOSE DE ALMEIDA MATHIAS, 194 - Rosário - Distrito de Cocais</span>
                    <span>Barão de Cocais/MG - 35.975-000</span>
                </div>
                <div className="footer-column footer-right">
                    <span>(31) 99000-8738</span>
                    <span>(31) 97252-2843</span>
                    <span>(31) 99731-7084</span>
                </div>
            </footer>
        </div>
    );
};
export default HomeGeral;
