import React from 'react';
import { FaCheck } from 'react-icons/fa';

const TopDescricao = ({ sections, classNamePrefix }) => {
    return (
        <div className={`${classNamePrefix}-description-container`}>
            {sections.map((section, index) => (
                <div key={index} className={`${classNamePrefix}-description-section`}>
                    <img src={section.urlimagem} alt={`Descrição ${index + 1}`} className={`${classNamePrefix}-description-image`} />
                    <div className={`${classNamePrefix}-description-text`}>
                        {section.texto.split('\r\n').map((line, i) => (
                            <React.Fragment key={i}>
                                {line.split('**').map((part, j) => (
                                    <React.Fragment key={j}>
                                        {part}
                                        {j < line.split('**').length - 1 && <FaCheck />}
                                    </React.Fragment>
                                ))}
                                <br />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TopDescricao;
