import React, { useEffect, useState, useRef } from 'react';
import './TypingText.css';

const TypingText = ({ text }) => {
    const [typedText, setTypedText] = useState('');
    const containerRef = useRef(null);

    useEffect(() => {
        let currentText = '';
        let isErrorMade = false;
        let corrected = false;
        let errorLength = 0;
        let i = 0;

        const type = () => {
            if (i < text.length) {
                if (!isErrorMade && Math.random() <= 0.0085) {
                    const errorChars = Math.floor(Math.random() * 4) + 1;
                    const randomString = Array.from({ length: errorChars }, () => String.fromCharCode(97 + Math.floor(Math.random() * 26))).join('');
                    currentText += randomString;
                    isErrorMade = true;
                    errorLength = errorChars;
                } else if (isErrorMade && !corrected) {
                    setTimeout(() => {
                        const deleteChar = () => {
                            if (errorLength > 0) {
                                currentText = currentText.slice(0, -1);
                                errorLength--;
                                setTypedText(currentText);
                                setTimeout(deleteChar, 140);
                            } else {
                                corrected = true;
                                setTimeout(type, 300);
                            }
                        };
                        deleteChar();
                    }, 250);
                    return;
                } else {
                    currentText += text.charAt(i);
                    i++;
                    isErrorMade = false;
                    corrected = false;
                }
                setTypedText(currentText);
                setTimeout(type, isErrorMade ? 120 : 70);
            }
        };
        type();
    }, [text]);

    return (
        <div className="topchef-typewriter-container" ref={containerRef}>
            <span className="topchef-typewriter-cursor">{typedText}</span>
        </div>
    );
};

export default TypingText;
