import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './AlertModal.css';
import { useNavigate } from 'react-router-dom';

const AlertModal = ({ show, onClose, message, type, redirectLink = null, timeout = null, onConfirm }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (show && timeout && timeout > 0) {
            const timer = setTimeout(() => {
                if (redirectLink) {
                    navigate(redirectLink);
                }
                onClose();
            }, timeout);

            return () => clearTimeout(timer);
        }
    }, [show, redirectLink, timeout, navigate, onClose]);

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        if (redirectLink) {
            navigate(redirectLink);
        }
        onClose();
    };

    const handleClose = () => {
        if (redirectLink) {
            navigate(redirectLink);
        }
        onClose();
    };

    const buttonMap = {
        success: 'Ótimo!',
        error: 'Entendido...',
        warning: 'Ok',
        question: 'Sim',
        default: 'Mensagem'
    };

    const bodyMap = {
        success: (
            <div className="alert-modal-body-container">
                <div className="alert-modal-body alert-modal-success">
                    <div className="alert-body-success">
                        <span>{message}</span>
                    </div>
                    <Button variant="secondary" className="alert-button" onClick={handleClose}>
                        {buttonMap.success}
                    </Button>
                </div>
                <img className="alert-image" src="https://topgpt.azureedge.net/site/janine_feliz.png" alt="Sucesso" />
            </div>
        ),
        error: (
            <div className="alert-modal-body-container">
                <div className="alert-modal-body alert-modal-error">
                    <div className="alert-body-error">
                        <span dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br>') }} />
                    </div>
                    <Button variant="secondary" className="alert-button" onClick={handleClose}>
                        {buttonMap.error}
                    </Button>
                </div>
                <img className="alert-image" src="https://topgpt.azureedge.net/site/janine_brava.png" alt="Erro" />
            </div>
        ),
        warning: (
            <div className="alert-modal-body-container">
                <div className="alert-modal-body alert-modal-warning">
                    <div className="alert-body-warning">
                        <span>{message}</span>
                    </div>
                    <Button variant="secondary" className="alert-button" onClick={handleClose}>
                        {buttonMap.warning}
                    </Button>
                </div>
                <img className="alert-image" src="https://topgpt.azureedge.net/site/warning.png" alt="Alerta" />
            </div>
        ),
        question: (
            <div className="alert-modal-body-container">
                <div className="alert-modal-body alert-modal-question">
                    <div className="alert-body-question">
                        <span>{message}</span>
                    </div>
                    <Button variant="secondary" className="alert-button" onClick={handleConfirm}>
                        Sim
                    </Button>
                    <Button variant="secondary" className="alert-button" onClick={handleClose}>
                        Não
                    </Button>
                </div>
                <img className="alert-image" src="https://topgpt.azureedge.net/site/janine_feliz.png" alt="Pergunta" />
            </div>
        ),
        default: (
            <div className="alert-modal-body-container">
                <div className="alert-modal-body alert-modal-default">
                    <div className="alert-body-default">
                        <p>{message}</p>
                    </div>
                    <Button variant="secondary" className="alert-button" onClick={handleClose}>
                        {buttonMap.default}
                    </Button>
                </div>
                <img className="alert-image" src="https://topgpt.azureedge.net/site/default.png" alt="Mensagem" />
            </div>
        )
    };

    const renderModalBody = () => bodyMap[type] || bodyMap.default;

    return (
        <Modal
            show={show}
            onHide={handleClose}
            className="alert-modal"
            dialogClassName="alert-modal-dialog"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>{renderModalBody()}</Modal.Body>
        </Modal>
    );
};

export default AlertModal;
