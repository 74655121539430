import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NewUserForm from './NewUserForm';
import EnderecoForm from './EnderecoForm';
import PagamentoForm from './PagamentoForm';
import FormContainer from '../objects/FormContainer';
import { useModal } from '../objects/ModalProvider';

const NewUser = () => {
    const navigate = useNavigate();
    const { assist } = useParams();
    const [formStep, setFormStep] = useState('usuario');
    const { showModal } = useModal();
    const [idUsuario, setidUsuario] = useState(null);

    const handleNextStep = (title, message, type, redirectLink = null, timeout = 5000) => {
        showModal(title, message, type, redirectLink, timeout);

        switch (assist)
        { 
            case '2':
                showModal('Cadastro concluído com sucesso!', 'Cadastro concluído com sucesso!', 'success', '/', timeout);
                break;
            case '3':
                if (formStep === 'usuario') {
                    showModal(title, message, 'success', redirectLink, timeout);
                    setFormStep('pagamento');
                }
                else if (formStep === 'pagamento') {
                    showModal('Cadastro concluído com sucesso!', 'Cadastro concluído com sucesso!!', 'success', '/', timeout);
                }
                break;
            default:
                break;
        }

        /*
        if (assist === '1')
        { 
            showModal('Cadastro concluído com sucesso!', 'Cadastro concluído com sucesso!', 'success', '/', timeout);
        }
        else if (assist === '2') {
            if (formStep === 'usuario') {
                showModal(title, message, 'success', redirectLink, timeout);
                setFormStep('endereco');
            }
            else if (formStep === 'endereco') {
                showModal(title, message, 'success', redirectLink, timeout);
                setFormStep('pagamento');
            }
            else if (formStep === 'pagamento') {
                showModal('Cadastro concluído com sucesso!', 'Cadastro concluído com sucesso!!', 'success', '/', timeout);
            }
        }
        */
    };

    const handleCancel = () => navigate('/');

    const renderFormStep = () => {
        switch (formStep) {
            case 'usuario':
                if (assist === '2')
                    return <NewUserForm onSave={() => handleNextStep('', 'Usuário salvo com sucesso!', 'success', '/', 2000)} onCancel={handleCancel} assist={assist} submitLabel={assist === "1" ? "Confirmar" : "Próximo"} setidUsuario={setidUsuario} />;
                else if (assist === '3')
                    return <NewUserForm onSave={() => handleNextStep('', 'Usuário salvo com sucesso!', 'success', null, 2000)} onCancel={handleCancel} assist={assist} submitLabel={assist === "1" ? "Confirmar" : "Próximo"} setidUsuario={setidUsuario} />;
                break;
            case 'endereco':
                return <EnderecoForm onContinue={() => handleNextStep('', 'Endereço salvo com sucesso!', 'success', null, 2000)} onCancel={handleCancel} submitLabel="Próximo" idUsuario={idUsuario} />;
            case 'pagamento':
                return <PagamentoForm onSave={() => handleNextStep('', 'Pagamento realizado com sucesso!', 'success', '/', 2000)} onCancel={handleCancel} submitLabel="Finalizar" />;
            default:
                return null;
        }
    };

    return (
        <FormContainer>
            <h2>Seja bem vindo !!!</h2>
            <br />
            {renderFormStep()}
        </FormContainer>
    );
};

export default NewUser;
