import React, { useEffect, useState } from 'react';
import './TopChat.css';

const Chat = ({ messages, onAllMessagesDisplayed }) => {
    const [displayedMessages, setDisplayedMessages] = useState([]);
    const [messagesDisplayed, setMessagesDisplayed] = useState(false);

    useEffect(() => {
        if (messages.length === 0 || messagesDisplayed) {
            return;
        }

        let index = 0;
        const interval = setInterval(() => {
            if (index < messages.length) {
                const message = messages[index];
                if (message && message.origem && message.mensagem) {
                    setDisplayedMessages(prevMessages => [...prevMessages, message]);
                }
                index++;
            } else {
                clearInterval(interval);
                setMessagesDisplayed(true);
                if (onAllMessagesDisplayed) {
                    onAllMessagesDisplayed();
                }
            }
        }, 500); // Ajuste o tempo de intervalo conforme necess�rio

        return () => clearInterval(interval);
    }, [messages, messagesDisplayed, onAllMessagesDisplayed]);

    return (
        <div className="chat-container">
            <div className="chat-box">
                <div className="chat-header">
                    <span>TopChef</span>
                    <div className="icons">
                        <div className="icon"><i className="fas fa-video"></i></div>
                        <div className="icon"><i className="fas fa-phone-alt"></i></div>
                        <div className="icon"><i className="fas fa-ellipsis-v"></i></div>
                    </div>
                </div>
                <div className="chat-content">
                    {displayedMessages.map((msg, index) => (
                        <div key={index} className={`message ${msg.origem}`}>
                            {msg.mensagem.split('\r\n').map((line, i) => (
                                <React.Fragment key={i}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    ))}
                </div>
                {/*
                <div className="chat-footer">
                    <i className="fas fa-paperclip"></i>
                    <input type="text" placeholder="Digite sua mensagem..." />
                    <i className="fas fa-microphone"></i>
                    <i className="fas fa-paper-plane"></i>
                </div>
                <div className="android-nav-bar">
                    <i className="fas fa-home"></i>
                    <i className="fas fa-circle"></i>
                    <i className="fas fa-square"></i>
                </div>*/}
            </div>
        </div>
    );
};

export default Chat;
