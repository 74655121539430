import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import "./HomeUser.css";

const HomeUser = () => {
    const { userId } = useAuth();
    const navigate = useNavigate();
    const [userSections, setUserSections] = useState([]);
    const { token } = useAuth();

    useEffect(() => {
        const fetchUserAssistentes = async () => {
            try {
                const response = await fetch(`./home/menuusuario?idUsuario=${userId}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const data = await response.json();

                if (data.length === 0) {
                    navigate('./cadastro');
                    return;
                }

                const formattedUserSections = data.map(menu => ({
                    id: menu.id,
                    nome: menu.nome,
                    descricao: menu.descricao,
                    urlMenu: menu.urlMenu,
                    urlImagem: menu.urlImagem,
                    textoBotao: menu.textoBotao,
                    dashboard: menu.dashboard,
                }));
                setUserSections(formattedUserSections);
            } catch (error) {
                console.error('Error fetching user assistentes:', error);
            }
        };

        fetchUserAssistentes();
    }, [userId, navigate, token]);

    const handleCardClick = (urlMenu) => {
        if (urlMenu) {
            navigate(urlMenu);
        }
    };

    return (
        <div className="page-container">
            <div className="sections-menu">
                {userSections.map((section, index) => (
                    <div key={index} className={`menu-wrapper ${section.urlMenu ? 'clickable' : ''}`} onClick={() => handleCardClick(section.urlMenu)}>
                        <div className="menu">
                            <img src={section.urlImagem} alt={section.nome} className="menu-image" />
                            <div className="menu-content">
                                <span>{section.descricao}</span>
                            </div>
                        </div>
                        {section.dashboard && (
                            <div className="menu-dashboard" dangerouslySetInnerHTML={{ __html: section.dashboard }}></div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeUser;
