import React, { useState, useEffect } from 'react';
import FormButtonRow from '../objects/FormButtonRow';
import InputMask from 'react-input-mask';
import { FaSearch } from 'react-icons/fa';
import { useModal } from '../objects/ModalProvider';
import './EnderecoForm.css';
import { useAuth } from '../auth/AuthContext';


const EnderecoForm = ({ onContinue, onCancel, submitLabel, idUsuario }) => {
    const [ufs, setUfs] = useState([]);
    const { token } = useAuth();

    const [endereco, setEndereco] = useState({
        cep: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        idUsuario: idUsuario,
    });
    const [loading, setLoading] = useState(false);
    const [cepError, setCepError] = useState('');
    const { showModal } = useModal();

    useEffect(() => {
        const fetchUfs = async () => {
            try {
                const response = await fetch('./endereco/ufs', { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const text = await response.text();
                const data = JSON.parse(text);
                setUfs(data);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchUfs();
    }, [token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('./endereco', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(endereco)
            });
            if (response.ok) {
                const data = await response.json();
                onContinue(data);
            } else {
                const text = await response.text();
                const data = JSON.parse(text);
                const errorMessages = Object.entries(data.errors || {})
                    .map(([key, value]) => `${key}: ${value.join(', ')}`)
                    .join('\n\n');
                showModal('Erro ao salvar endereço:', errorMessages, 'error');
            }
        } catch (error) {
            console.error("Failed to post address data:", error);
            showModal('Erro técnico ao salvar.', 'Tente novamente.', 'error');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEndereco(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCepSearch = async () => {
        if (endereco.cep) {
            try {
                setLoading(true);
                setCepError('');
                const response = await fetch(`./endereco/consultarCep?cep=${endereco.cep}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const text = await response.text();
                const addressData = JSON.parse(text);

                if (response.ok) {
                    setEndereco(prevState => ({
                        ...prevState,
                        rua: addressData.logradouro,
                        bairro: addressData.bairro,
                        cidade: addressData.localidade,
                        estado: ufs.find(uf => uf.sigla === addressData.uf)?.id || prevState.estado,
                    }));
                } else {
                    setCepError(addressData.message);
                }
            } catch (error) {
                setCepError(error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCepKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleCepSearch();
        }
    };

    return (
        <form onSubmit={handleSubmit} className="endereco-form">
            <div className="form-row">

                <div className="form-group">
                    <label>CEP:</label>
                    <div className="cep-input-wrapper">
                        <InputMask
                            mask="99999-999"
                            maskChar={null}
                            name="cep"
                            value={endereco.cep}
                            onChange={handleChange}
                            onKeyDown={handleCepKeyDown}
                            className="form-control"
                            required
                        />
                        <button type="button" className="cep-search-btn" onClick={handleCepSearch} disabled={loading}>
                            <FaSearch className="fa-search" />
                        </button>
                    </div>
                    {cepError && <small className="form-text text-danger">{cepError}</small>}
                </div>


            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>Endereço:</label>
                    <input
                        type="text"
                        name="rua"
                        value={endereco.rua}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <label>Número:</label>
                    <input
                        type="text"
                        name="numero"
                        value={endereco.numero}
                        onChange={handleChange}
                        className="form-control"
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <label>Complemento:</label>
                    <input
                        type="text"
                        name="complemento"
                        value={endereco.complemento}
                        onChange={handleChange}
                        className="form-control"
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <label>Bairro:</label>
                    <input
                        type="text"
                        name="bairro"
                        value={endereco.bairro}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <label>Cidade:</label>
                    <input
                        type="text"
                        name="cidade"
                        value={endereco.cidade}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <label>Estado:</label>
                    <select
                        name="estado"
                        value={endereco.estado}
                        onChange={handleChange}
                        className="form-control"
                        required
                        disabled={loading}
                    >
                        <option value="">Selecione um estado</option>
                        {ufs.map(uf => (
                            <option key={uf.id} value={uf.id}>
                                {uf.nome}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <FormButtonRow onSubmit={handleSubmit} onCancel={onCancel} submitLabel={submitLabel} />
        </form>
    );
};

export default EnderecoForm;
