import React, { useState } from 'react';
import FormButtonRow from '../objects/FormButtonRow';

const ToggleSwitch = ({ isOn, handleToggle }) => (
    <label className="switch">
        <input type="checkbox" checked={isOn} onChange={handleToggle} />
        <span className="slider"></span>
    </label>
);

const PagamentoForm = ({ onSave, onCancel, submitLabel }) => {
    const [pagamento, setPagamento] = useState({
        id: '',
        intencaoFutura: true
    });

    const handleToggle = () => {
        setPagamento(prevPagamento => ({ ...prevPagamento, intencaoFutura: !prevPagamento.intencaoFutura }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSave(pagamento); 
    };

    return (
        <form onSubmit={handleSubmit}>
            <br />
            <p>
                Informamos que o acesso ao sistema está restrito para amigos e familiares. Estamos atualmente em fase de ajustes para garantir uma excelente experiência.
            </p>
            <p>
                Caso tenha interesse em participar, mantenha a caixa de opção selecionada, finalize o cadastro que entraremos em contato.
            </p>
            <p>
                Obrigado!
            </p>
            <br />

            <div>
                <label>Tenho interesse:</label>
                <ToggleSwitch
                    isOn={pagamento.intencaoFutura}
                    handleToggle={handleToggle}
                />
            </div>
            <br />
            <br />

            {/*
            <div className="form-group">
                <label>Número do Cartão:</label>
                <input type="text" name="numeroCartao" className="form-control" required />
            </div>
            <div className="form-group">
                <label>Validade:</label>
                <input type="month" name="validade" className="form-control" required />
            </div>
            <div className="form-group">
                <label>CVV:</label>
                <input type="text" name="cvv" className="form-control" required />
            </div>
            */}
            <FormButtonRow onSubmit={handleSubmit} onCancel={onCancel} submitLabel={submitLabel} disabled={!pagamento.intencaoFutura} />
        </form>
    );
};

export default PagamentoForm;
