import React, { useEffect, useState } from 'react';
import './TopSecretario.css';
import Chat from '../objects/TopChat';
import TopChefDesc from '../objects/TopDescricao';
import TypingText from '../objects/TypingText';
import { useModal } from '../objects/ModalProvider';
import { useAuth } from '../auth/AuthContext';

const TopSecretario = () => {
    const [messages, setMessages] = useState([]);
    const [sections, setSections] = useState([]);
    const [chatLoaded, setChatLoaded] = useState(false);
    const [startTyping, setStartTyping] = useState(false);
    const { showModal } = useModal();
    const { anonToken } = useAuth();

    useEffect(() => {
        // Adicionar um pequeno atraso para garantir que o scroll aconteça após o carregamento do layout
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100); // 100ms de atraso

        const fetchMessages = async () => {
            try {
                const response = await fetch('institucional/topsecretario', { method: 'GET', headers: { 'Authorization': `Bearer ${anonToken}`, } });
                const data = await response.json();
                if (Array.isArray(data)) {
                    const validMessages = data.filter(msg => msg && msg.origem && msg.mensagem);
                    setMessages(validMessages);
                } else {
                    console.error('Dados recebidos para mensagens não são um array:', data);
                }
            } catch (error) {
                console.error('Erro ao buscar mensagens:', error);
            }
        };

        const fetchSections = async () => {
            try {
                const response = await fetch('institucional/topsecretariodesc', { method: 'GET', headers: { 'Authorization': `Bearer ${anonToken}`, } });
                const data = await response.json();
                if (Array.isArray(data)) {
                    const validSections = data.filter(section => section && section.texto && section.urlimagem);
                    setSections(validSections);
                } else {
                    console.error('Dados recebidos para descrições não são um array:', data);
                }
            } catch (error) {
                console.error('Erro ao buscar descrições:', error);
            }
        };

        fetchMessages();
        fetchSections();

        return () => clearTimeout(timer); 
    }, [anonToken]);

    useEffect(() => {
        if (chatLoaded) {
            const timer = setTimeout(() => {
                setStartTyping(true);
            }, 500); // Aguardar meio segundo após o chat estar carregado
            return () => clearTimeout(timer);
        }
    }, [chatLoaded]);

    const handleChatLoaded = () => {
        setChatLoaded(true);
    };

    const handleButtonClick = () => {
        showModal('Excelente decisão!', 'Excelente decisão!', 'success', './newuser/3', 1000);
    };

    return (
        <div className="topsecretario-page">
            <div className="topsecretario-header-section">
                <div className="topsecretario-impact-text-container">
                    <div className="topsecretario-impact-text">
                        {startTyping && <TypingText text="Gerencie seus compromissos de forma prática e eficiente, diretamente no WhatsApp!" />}
                    </div>
                </div>
                <Chat messages={messages} onAllMessagesDisplayed={handleChatLoaded} />
            </div>
            <TopChefDesc sections={sections} classNamePrefix="topsecretario" />
            <button className="topsecretario-giant-button" onClick={handleButtonClick}>
                Assine agora !
            </button>
        </div>
    );
};

export default TopSecretario;
