import React, { useState } from 'react';
import FormButtonRow from '../objects/FormButtonRow';
import InputMask from 'react-input-mask';
import { useModal } from '../objects/ModalProvider';
import { useAuth } from '../auth/AuthContext';

const UsuarioForm = ({ onSave, onCancel, assist, submitLabel, setidUsuario }) => {
    const { anonToken } = useAuth();

    const [mask, setMask] = useState("(99) 99999-9999");
    const [usuario, setUsuario] = useState({
        IdAssistente: assist,
        Telefone: '',
        Nome: '',
        Email: '',
        Senha: '',
        ConfirmaSenha: '',
    });
    const { showModal } = useModal();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('usuario', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${anonToken}`,
                },
                body: JSON.stringify(usuario)
            });
            if (response.ok) {
                const text = await response.text();
                const newUser = JSON.parse(text);
                setidUsuario(newUser.id);
                onSave();
            } else {
                const text = await response.text();
                const data = JSON.parse(text);
                const errorMessages = Object.entries(data.errors || {})
                    .map(([key, value]) => `${key}: ${value.join(', ')}`)
                    .join('\n');
                showModal('Erro ao salvar usuário:', errorMessages, 'error');
            }
        } catch (error) {
            console.error("Failed to post user data:", error);
            showModal('Erro técnico ao salvar.', 'Tente novamente.', 'error');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUsuario(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'Telefone') {
            const numericValue = value.replace(/\D/g, '');
            if (numericValue[2] >= 6) {
                setMask("(99) 99999-9999");
            } else {
                setMask("(99) 9999-9999");
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="usuario-form">
            <div className="form-row">
                <div className="form-group">
                    <label>Nome:</label>
                    <input
                        type="text"
                        name="Nome"
                        value={usuario.Nome}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>Telefone:</label>
                    <InputMask
                        mask={mask}
                        maskChar={null}
                        value={usuario.Telefone}
                        onChange={handleChange}
                        type="tel"
                        name="Telefone"
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>E-mail:</label>
                    <input
                        type="email"
                        name="Email"
                        value={usuario.Email || ''}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>Senha:</label>
                    <input
                        type="password"
                        name="Senha"
                        value={usuario.Senha}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Confirme a Senha:</label>
                    <input
                        type="password"
                        name="ConfirmaSenha"
                        value={usuario.ConfirmaSenha}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
            </div>
            <FormButtonRow onSubmit={handleSubmit} onCancel={onCancel} submitLabel={submitLabel} />
        </form>
    );
};

export default UsuarioForm;
